<template>
  <div class="box">
    <p class="description" v-html="theory.description"></p>
    <div class="video" v-if="theory.link">
      <video-player
        :videoId="theory.link"
        :resourceId="theory.id"
        :dropboxLink="getDropboxLink"
      />
    </div>
    <div class="images" v-if="theory.images && theory.images.length > 0">
      <img
        @click="showFullImage(image)"
        :src="getImageUrl(image)"
        v-for="image in theory.images"
        :key="image.id"
      />
    </div>
  </div>
</template>

<script>
import { SERVER_URL } from "../../../axios";
import VideoPlayer from "../../../components/VideoPlayer.vue";
export default {
  components: { VideoPlayer },
  name: "TheoryPracticeLessonTheory",
  props: ["theory"],
  methods: {
    getImageUrl(image) {
      const splittedPath = image.path.split(".");
      const extension = splittedPath[splittedPath.length - 1];
      const url = `${SERVER_URL}/lessonsImages/${image.id}.${extension}`;
      return url;
    },
    showFullImage(image) {
      const url = this.getImageUrl(image);
      window.open(url, "_blank");
    },
  },
  computed: {
    getDropboxLink() {
      let courseLink = this.theory && this.theory.link ? this.theory.link : "";
      if (courseLink.includes("dropbox")) {
        const videoLink = courseLink.replace("dl=0", "raw=1");
        return videoLink;
      } else {
        return "";
      }
    },
  },
};
</script>

<style scoped>
.box {
  margin: 1rem 0px 4rem 0px;
}
.video {
  margin: 0 auto;
  margin-bottom: 2rem;
  max-width: 500px;
}
.images {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
}
.images img {
  height: 300px;
  cursor: pointer;
  object-fit: cover;
}
::v-deep p img {
  max-width: 100% !important;
}
</style>