import Vue from "vue";
import Vuex from "vuex";
import app from "./store/app";
import auth from "./store/auth";
import payment from "./store/payment";
import snackbar from "./store/snackbar";
import packages from "./store/packages";
import timePackages from "./store/timePackages";
import videos from "./store/videos";
import comments from "./store/comments";
import giftCard from "./store/giftCard";
import workshops from "./store/workshops";
import users from "./store/users";
import userSubscriptions from "./store/userSubscriptions";
import movieCategories from "./store/movieCategories";
import movieSubCategories from "./store/movieSubcategories";
import boughtProducts from "./store/boughtProducts";
import sequencingWorkshops from "./store/sequencingWorkshop";
import shopProducts from "./store/shopProducts";
import coursesTickets from "./store/coursesTickets";
import packages24h from "./store/packages24";
import theoryPracticeWorkshops from "./store/theoryPracticeWorkshop";
import theoryPracticeWorkshopsLessons from "./store/theoryPracticeWorkshopLesson";
import theoryPracticeWorkshopsLessonsPractices from "./store/theoryPracticeWorkshopLessonPractice";
import theoryPracticeWorkshopsLessonsTheories from "./store/theoryPracticeWorkshopLessonTheories";
import theoryPracticeWorkshopsLessonsAttachments from "./store/theoryPracticeWorkshopLessonAttachment";
import discountCode from "./store/discountCode";
import timePackagesLinks from "./store/timePackagesLinks";
import specialOffers from "./store/specialOffer";

Vue.use(Vuex);
import createPersistedState from "vuex-persistedstate";
export default new Vuex.Store({
  plugins: [createPersistedState()],
  modules: {
    app,
    auth,
    payment,
    packages,
    snackbar,
    timePackages,
    videos,
    comments,
    giftCard,
    workshops,
    users,
    userSubscriptions,
    movieCategories,
    boughtProducts,
    movieSubCategories,
    sequencingWorkshops,
    shopProducts,
    coursesTickets,
    packages24h,
    theoryPracticeWorkshops,
    theoryPracticeWorkshopsLessons,
    theoryPracticeWorkshopsLessonsPractices,
    theoryPracticeWorkshopsLessonsTheories,
    theoryPracticeWorkshopsLessonsAttachments,
    discountCode,
    timePackagesLinks,
    specialOffers,
  },
});
