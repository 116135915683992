import Vue from "vue";
import jwt_decode from "jwt-decode";
import VueRouter from "vue-router";
import axios from "./axios";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import { SENTRY_DSN, isProduction } from "./config/config";

// import Error from "./views/Error.vue";
import Account from "./views/Account.vue";
import PaymentSuccess from "./views/PaymentSuccess.vue";
import NewsletterSuccess from "./views/NewsletterSuccess.vue";
import SequencingWorkshopSignupSuccess from "./views/SequencingWorkshopSignupSuccess.vue";
import Newsletter from "./views/Newsletter.vue";
//import Login from "./views/Login.vue";
import Payment from "./views/Payment.vue";
import Videos from "./views/Videos.vue";
import VideosDemo from "./views/VideosDemo.vue";
import ResetPassword from "./views/ResetPassword.vue";
import Auth from "./views/Auth.vue";
import SubscriptionPayment from "./views/SubscriptionPayment.vue";
import TimeSubscriptions from "./views/TimeSubscriptions.vue";
import Courses from "./views/Courses.vue";
import RegisterSuccess from "./views/RegisterSuccess";
import UserPaymentSuccess from "./views/UserPaymentSuccess";
import UserPaymentForBeginnersSuccess from "./views/UserPaymentForBeginnersSuccess";
import UserVerified from "./views/UserVerified";
import SubscriptionsManagement from "./views/SubscriptionsManagement.vue";
import SubscriptionsForBeginnersManagement from "./views/SubscriptionsForBeginnersManagement.vue";
import SubscriptionsItems from "./views/SubscriptionsItems.vue";
import SubscriptionsForBeginnersItems from "./views/SubscriptionsForBeginnersItems.vue";
import GiftCardPayment from "./views/GiftCardPayment.vue";
import GiftCardEnterCode from "./views/GiftCardEnterCode.vue";
import GiftCardsManagement from "./views/GiftCardsManagement.vue";
import GiftCardsForBeginnersManagement from "./views/GiftCardsForBeginnersManagement.vue";
import GiftCardPaymentSuccess from "./views/GiftCardPaymentSuccess.vue";
import GiftCardPaymentForBeginnersSuccess from "./views/GiftCardPaymentForBeginnersSuccess.vue";
import Workshops from "./views/Workshops.vue";
// import WorkshopVacationWithYoga from "./views/WorkshopVacationWithYoga.vue";
import WorkshopsVideos from "./views/WorkshopsVideos.vue";
import WorkshopsPayment from "./views/WorkshopsPayment.vue";
import WorkshopsPaymentSuccess from "./views/WorkshopsPaymentSuccess.vue";
import UsersListManagement from "./views/UsersListManagement.vue";
import MovieCategoriesManagement from "./views/MovieCategoriesManagement.vue";
import MovieCategoriesForBeginnersManagement from "./views/MovieCategoriesForBeginnersManagement.vue";
import BoughtProductsManagement from "./views/BoughtProductsManagement.vue";
import WorkshopsManagement from "./views/WorkshopsManagement.vue";
import WorkshopsItems from "./views/WorkshopsItems.vue";
import NotFound from "./views/NotFound.vue";
import GiftVouchers from "./views/GiftVouchers.vue";
import UserAccount from "./views/UserAccount.vue";
import MovieSubcategoriesManagement from "./views/MovieSubcategoriesManagement.vue";
import MovieSubcategoriesForBeginnersManagement from "./views/MovieSubcategoriesForBeginnersManagement.vue";
import MovieSubcategoriesL2Management from "./views/MovieSubcategoriesL2Management.vue";
import MovieSubcategoriesL2ForBeginnersManagement from "./views/MovieSubcategoriesL2ForBeginnersManagement.vue";
import SubcategoryView from "./views/SubcategoryView.vue";
import CoursesForBeginners from "./views/CoursesForBeginners.vue";
import TimeSubscriptionsForBeginners from "./views/TimeSubscriptionsForBeginners.vue";
import GiftVouchersForBeginners from "./views/GiftVouchersForBeginners.vue";
import SequencingWorkshopPayment from "./views/SequencingWorkshopPayment.vue";
import SequencingWorkshopSignup from "./views/SequencingWorkshopSignup.vue";
import SequencingWorkshopsManagement from "./views/SequencingWorkshopsManagement.vue";
import SequencingWorkshopsItems from "./views/SequencingWorkshopsItems.vue";
import SequencingWorkshops from "./views/SequencingWorkshops.vue";
import SequencingWorkshopsVideos from "./views/SequencingWorkshopsVideos.vue";
import SequencingWorkshopsPaymentSuccess from "./views/SequencingWorkshopsPaymentSuccess.vue";
import SubcategoryL2View from "./views/SubcategoryL2View.vue";
import ShopProducts from "./views/shop/ShopProducts.vue";
import ShopProductCart from "./views/shop/ShopProductCart.vue";
import ShopBucket from "./views/shop/ShopBucket.vue";
import ShopProductsManagement from "./views/ShopProductsManagement.vue";
import ShopProductsDiscountsManagement from "./views/ShopProductsDiscountsManagement.vue";
import ShopProductsPayment from "./views/shop/ShopProductsPayment.vue";
import ShopProductVideos from "./views/shop/ShopProductVideos.vue";
import ShopProductVideo from "./views/shop/ShopProductVideo.vue";
import ShopProductsVideosManagement from "./views/ShopProductsVideosManagement.vue";
import ShopProductsPaymentSuccess from "./views/shop/ShopProductsPaymentSuccess.vue";
import CoursesTicketsPaymentSuccess from "./views/CoursesTicketsPaymentSuccess.vue";
import CoursesTicketsPayment from "./views/CoursesTicketsPayment.vue";
import TicketsManagement from "./views/TicketsManagement.vue";
import TicketsBeginnersManagement from "./views/TicketsBeginnersManagement.vue";
import TicketsLinksManagement from "./views/TicketsLinksManagement.vue";
import TicketsBeginnersLinksManagement from "./views/TicketsBeginnersLinksManagement.vue";
import TimePackagesLinksManagement from "./views/TimePackagesLinksManagement.vue";
// import SubscriptionsExtendedManagement from "./views/SubscriptionsExtendedManagement.vue";
// import SubscriptionsItemsExtendedManagement from "./views/SubscriptionsItemsExtendedManagement.vue";
// import CoursesExtended from "./views/CoursesExtended.vue";
// import VideosDemoExtendedSubscription from "./views/VideosDemoExtendedSubscription.vue";
// import VideosExtendedSubscription from "./views/VideosExtendedSubscription.vue";
// import MovieCategoriesExtendedManagement from "./views/MovieCategoriesExtendedManagement.vue";
// import MovieSubcategoriesForExtendedManagement from "./views/MovieSubcategoriesForExtendedManagement.vue";
// import MovieSubcategoriesL2ForExtendedManagement from "./views/MovieSubcategoriesL2ForExtendedManagement.vue";
import Movie24hPaymentSuccess from "./views/Movie24hPaymentSuccess.vue";
import Videos24h from "./views/Videos24h.vue";
import TariffWorkshopsManagement from "./views/TariffWorkshopsManagement.vue";
import TariffWorkshopsItems from "./views/TariffWorkshopsItems.vue";
import ScheduleManagement from "./views/ScheduleManagement.vue";
import TheoryPracticeWorkshopsManagement from "./views/TheoryPracticeWorkshopsManagement.vue";
import TheoryPracticeWorkshopsLessonsManagement from "./views/TheoryPracticeWorkshopsLessonsManagement.vue";
import TheoryPracticeWorkshopsLessonsPracticesManagement from "./views/TheoryPracticeWorkshopsLessonsPracticesManagement.vue";
import TheoryPracticeWorkshopsLessonsTheoriesManagement from "./views/TheoryPracticeWorkshopsLessonsTheoriesManagement.vue";
import TheoryPracticeWorkshopsLessonsAttachmentsManagement from "./views/TheoryPracticeWorkshopsLessonsAttachmentsManagement.vue";
import TheoryPracticeWorkshopLessons from "./views/theoryPracticeWorkshops/TheoryPracticeWorkshopLessons.vue";
import TheoryPracticeWorkshopLessonTheories from "./views/theoryPracticeWorkshops/TheoryPracticeWorkshopLessonTheories.vue";
import TheoryPracticeWorkshopLessonPractices from "./views/theoryPracticeWorkshops/TheoryPracticeWorkshopLessonPractices.vue";
import TheoryPracticeWorkshopsPaymentSuccess from "./views/theoryPracticeWorkshops/TheoryPracticeWorkshopsPaymentSuccess.vue";
import TheoryPracticeWorkshopSignup from "./views/theoryPracticeWorkshops/TheoryPracticeWorkshopSignup.vue";
import TheoryPracticeWorkshopSignupSuccess from "./views/theoryPracticeWorkshops/TheoryPracticeWorkshopSignupSuccess.vue";
import TheoryPracticeWorkshopPayment from "./views/theoryPracticeWorkshops/TheoryPracticeWorkshopPayment.vue";

import SpecialOffersManagement from "./views/SpecialOffersManagement.vue";
import SpecialOffersPayment from "./views/SpecialOfferPayment.vue";
import SpecialOffers from "./views/SpecialOffers.vue";
import SpecialOffersVideos from "./views/SpecialOffersVideos.vue";
import SpecialOffersPaymentSuccess from "./views/SpecialOffersPaymentSuccess.vue";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/admin/kursy-online",
    name: "Account",
    component: Account,
    meta: {
      requiresAuth: true,
      withoutFloatingButton: true,
    },
  },
  {
    path: "/admin/warsztaty",
    name: "WorkshopsManagement",
    component: WorkshopsManagement,
    meta: {
      requiresAuth: true,
      withoutFloatingButton: true,
    },
  },
  {
    path: "/admin/subskrypcje",
    name: "SubscriptionsManagement",
    component: SubscriptionsManagement,
    meta: {
      requiresAuth: true,
      withoutFloatingButton: true,
    },
  },

  {
    path: "/admin/subskrypcje-podstawy",
    name: "SubscriptionsForBeginnersManagement",
    component: SubscriptionsForBeginnersManagement,
    meta: {
      requiresAuth: true,
      withoutFloatingButton: true,
    },
  },

  {
    path: "/admin/subskrypcje/kursy",
    name: "SubscriptionsItems",
    component: SubscriptionsItems,
    meta: {
      requiresAuth: true,
      withoutFloatingButton: true,
    },
  },

  {
    path: "/admin/subskrypcje-podstawy/kursy",
    name: "SubscriptionsForBeginnersItems",
    component: SubscriptionsForBeginnersItems,
    meta: {
      requiresAuth: true,
      withoutFloatingButton: true,
    },
  },

  {
    path: "/admin/podkategorie",
    name: "MovieSubcategoriesManagement",
    component: MovieSubcategoriesManagement,
    meta: {
      requiresAuth: true,
      withoutFloatingButton: true,
    },
  },
  {
    path: "/admin/podkategorie-podstawy",
    name: "MovieSubcategoriesForBeginnersManagement",
    component: MovieSubcategoriesForBeginnersManagement,
    meta: {
      requiresAuth: true,
      withoutFloatingButton: true,
    },
  },
  {
    path: "/admin/podkategorie-l2",
    name: "MovieSubcategoriesL2Management",
    component: MovieSubcategoriesL2Management,
    meta: {
      requiresAuth: true,
      withoutFloatingButton: true,
    },
  },
  {
    path: "/admin/podkategorie-l2-podstawy",
    name: "MovieSubcategoriesL2ForBeginnersManagement",
    component: MovieSubcategoriesL2ForBeginnersManagement,
    meta: {
      requiresAuth: true,
      withoutFloatingButton: true,
    },
  },
  {
    path: "/admin/warsztaty/kursy",
    name: "WorkshopsItems",
    component: WorkshopsItems,
    meta: {
      requiresAuth: true,
      withoutFloatingButton: true,
    },
  },
  {
    path: "/admin/kategorie",
    name: "MovieCategoriesManagement",
    component: MovieCategoriesManagement,
    meta: {
      requiresAuth: true,
      withoutFloatingButton: true,
    },
  },
  {
    path: "/admin/kategorie-podstawy",
    name: "MovieCategoriesForBeginnersManagement",
    component: MovieCategoriesForBeginnersManagement,
    meta: {
      requiresAuth: true,
      withoutFloatingButton: true,
    },
  },

  {
    path: "/admin/platnosci",
    name: "BoughtProductsManagement",
    component: BoughtProductsManagement,
    meta: {
      requiresAuth: true,
      withoutFloatingButton: true,
    },
  },
  {
    path: "/kursy-online/status-platnosci",
    name: "PaymentSuccess",
    component: PaymentSuccess,
  },
  {
    path: "/nagrania/status-platnosci",
    name: "Movie24hPaymentSuccess",
    component: Movie24hPaymentSuccess,
  },
  {
    path: "/subskrypcje/status-platnosci",
    name: "UserPaymentSuccess",
    component: UserPaymentSuccess,
  },
  {
    path: "/subskrypcje-podstawy/status-platnosci",
    name: "UserPaymentForBeginnersSuccess",
    component: UserPaymentForBeginnersSuccess,
  },

  {
    path: "/vouchery/status-platnosci",
    name: "GiftCardPaymentSuccess",
    component: GiftCardPaymentSuccess,
  },
  {
    path: "/vouchery-podstawy/status-platnosci",
    name: "GiftCardPaymentForBeginnersSuccess",
    component: GiftCardPaymentForBeginnersSuccess,
  },

  {
    path: "/warsztaty/status-platnosci",
    name: "WorkshopsPaymentSuccess",
    component: WorkshopsPaymentSuccess,
  },
  {
    path: "/newsletter/zapisano",
    name: "NewsletterSuccess",
    component: NewsletterSuccess,
  },
  {
    path: "/kursy-online/platnosc/:id",
    name: "Payment",
    component: Payment,
  },
  {
    path: "/kursy-online/nagranie/platnosc/:id",
    name: "Payment",
    component: Payment,
    params: { recording: 1 },
  },
  {
    path: "/account/dokonaj-platnosci/:id",
    name: "Payment",
    component: Payment,
  },
  {
    path: "/vouchery/platnosc",
    name: "GiftCardPayment",
    component: GiftCardPayment,
  },
  {
    path: "/account/voucher-payment",
    name: "GiftCardPayment",
    component: GiftCardPayment,
  },
  {
    path: "/vouchery/kod",
    name: "GiftCardEnterCode",
    component: GiftCardEnterCode,
  },
  {
    path: "/account/voucher-code",
    name: "GiftCardEnterCode",
    component: GiftCardEnterCode,
  },
  {
    path: "/moje-konto",
    name: "UserAccount",
    component: UserAccount,
  },
  {
    path: "/admin/vouchery",
    name: "GiftCardsManagement",
    component: GiftCardsManagement,
    meta: {
      withoutFloatingButton: true,
    },
  },
  {
    path: "/admin/vouchery-podstawy",
    name: "GiftCardsForBeginnersManagement",
    component: GiftCardsForBeginnersManagement,
    meta: {
      withoutFloatingButton: true,
    },
  },

  {
    path: "/admin/uzytkownicy",
    name: "UsersListManagement",
    component: UsersListManagement,
    meta: {
      withoutFloatingButton: true,
    },
  },
  {
    path: "/newsletter",
    name: "Newsletter",
    component: Newsletter,
  },
  {
    path: "/subskrypcje/video",
    name: "Videos",
    component: Videos,
    props: true,
    meta: {},
  },
  {
    path: "/warsztaty/video",
    name: "WorkshopsVideos",
    component: WorkshopsVideos,
    props: true,
    meta: {},
  },
  {
    path: "/subskrypcje/video-demo",
    name: "VideosDemo",
    component: VideosDemo,
    props: true,
    meta: {},
  },

  // {
  //   path: "/subskrypcje-rozszerzone/video",
  //   name: "VideosExtendedSubscription",
  //   component: VideosExtendedSubscription,
  //   props: true,
  //   meta: {},
  // },

  // {
  //   path: "/subskrypcje-rozszerzone/video-demo",
  //   name: "VideosDemoExtendedSubscription",
  //   component: VideosDemoExtendedSubscription,
  //   props: true,
  //   meta: {},
  // },

  {
    path: "/",
    name: "Courses",
    component: Courses,
    meta: {},
  },
  {
    path: "/logowanie",
    name: "Auth",
    component: Auth,
    meta: {
      withoutFloatingButton: true,
    },
  },
  {
    path: "/rejestracja",
    name: "Auth",
    component: Auth,
    meta: {
      withoutFloatingButton: true,
    },
  },
  {
    path: "/subskrypcje/platnosc",
    name: "SubscriptionPayment",
    component: SubscriptionPayment,
  },
  {
    path: "/subskrypcje",
    name: "TimeSubscriptions",
    component: TimeSubscriptions,
    props: true,
    meta: {},
  },
  {
    path: "/subskrypcje-podstawy",
    name: "TimeSubscriptionsForBeginners",
    component: TimeSubscriptionsForBeginners,
    props: true,
    meta: {},
  },
  {
    path: "/user/subscriptions",
    name: "TimeSubscriptions",
    component: TimeSubscriptions,
    props: true,
    meta: {},
  },
  {
    path: "/vouchery",
    name: "GiftVouchers",
    component: GiftVouchers,
    props: true,
    meta: {},
  },
  {
    path: "/vouchery-podstawy",
    name: "GiftVouchersForBeginners",
    component: GiftVouchersForBeginners,
    props: true,
    meta: {},
  },

  {
    path: "/subskrypcja",
    name: "Courses",
    component: Courses,
    meta: {
      savePosition: true,
      scrollTop: 0,
    },
    props: true,
  },
  {
    path: "/subskrypcja-podstawy",
    name: "CoursesForBeginners",
    component: CoursesForBeginners,
    meta: {
      savePosition: true,
      scrollTop: 0,
    },
    props: true,
  },

  // {
  //   path: "/subskrypcja-rozszerzona",
  //   name: "CoursesExtended",
  //   component: CoursesExtended,
  //   meta: {
  //     savePosition: true,
  //     scrollTop: 0,
  //   },
  //   props: true,
  // },

  {
    path: "/rejestracja-zakonczona",
    name: "RegisterSuccess",
    component: RegisterSuccess,
  },
  {
    path: "/weryfikacja",
    name: "UserVerified",
    component: UserVerified,
  },
  //previous path
  {
    path: "user/verification",
    name: "UserVerified",
    component: UserVerified,
  },
  {
    path: "/zmiana-hasla",
    name: "ResetPassword",
    component: ResetPassword,
    meta: {},
  },
  {
    path: "/warsztaty",
    name: "Workshops",
    component: Workshops,
    meta: {
      savePosition: true,
      scrollTop: 0,
    },
  },
  {
    path: "/bony-podarunkowe",
    name: "GiftVouchers",
    component: GiftVouchers,
  },
  {
    path: "/warsztaty/platnosc/:id",
    name: "WorkshopsPayment",
    component: WorkshopsPayment,
  },
  {
    path: "/konto",
    name: "UserAccount",
    component: UserAccount,
    meta: {},
  },
  {
    path: "/kategoria/:id",
    name: "SubcategoryView",
    component: SubcategoryView,
  },
  {
    path: "/podkategoria/:id",
    name: "SubcategoryL2View",
    component: SubcategoryL2View,
  },
  {
    path: "/warsztaty-z-sekwencjonowania/platnosc/:id",
    name: "SequencingWorkshopPayment",
    component: SequencingWorkshopPayment,
  },
  {
    path: "/admin/warsztaty-z-sekwencjonowania",
    name: "SequencingWorkshopsManagement",
    component: SequencingWorkshopsManagement,
  },
  {
    path: "/admin/teoria-i-praktyka",
    name: "TheoryPracticeWorkshopsManagement",
    component: TheoryPracticeWorkshopsManagement,
  },
  {
    path: "/admin/teoria-i-praktyka-lekcje",
    name: "TheoryPracticeWorkshopsLessonsManagement",
    component: TheoryPracticeWorkshopsLessonsManagement,
  },
  {
    path: "/admin/teoria-i-praktyka-lekcje-praktyka",
    name: "TheoryPracticeWorkshopsLessonsPracticesManagement",
    component: TheoryPracticeWorkshopsLessonsPracticesManagement,
  },
  {
    path: "/admin/teoria-i-praktyka-lekcje-teoria",
    name: "TheoryPracticeWorkshopsLessonsTheoriesManagement",
    component: TheoryPracticeWorkshopsLessonsTheoriesManagement,
  },
  {
    path: "/admin/teoria-i-praktyka-lekcje-zalaczniki",
    name: "TheoryPracticeWorkshopsLessonsAttachmentsManagement",
    component: TheoryPracticeWorkshopsLessonsAttachmentsManagement,
  },

  {
    path: "/admin/warsztaty-z-sekwencjonowania/kursy",
    name: "SequencingWorkshopsItems",
    component: SequencingWorkshopsItems,
  },
  {
    path: "/warsztaty-z-sekwencjonowania/zapisy/:id",
    name: "SequencingWorkshopSignup",
    component: SequencingWorkshopSignup,
  },
  {
    path: "/warsztaty-z-sekwencjonowania/zapisano",
    name: "SequencingWorkshopSignupSuccess",
    component: SequencingWorkshopSignupSuccess,
  },
  {
    path: "/warsztaty-z-sekwencjonowania/status-platnosci",
    name: "SequencingWorkshopsPaymentSuccess",
    component: SequencingWorkshopsPaymentSuccess,
  },
  {
    path: "/warsztaty-z-sekwencjonowania/:id",
    name: "SequencingWorkshops",
    component: SequencingWorkshops,
  },
  {
    path: "/warsztaty-z-taryfami/video/:id",
    name: "SequencingWorkshopVideo",
    component: SequencingWorkshopsVideos,
  },
  {
    path: "/warsztaty-z-taryfami/status-platnosci",
    name: "SequencingWorkshopsPaymentSuccess",
    component: SequencingWorkshopsPaymentSuccess,
  },
  {
    path: "/warsztaty-z-taryfami/:id",
    name: "SequencingWorkshops",
    component: SequencingWorkshops,
  },
  {
    path: "/warsztaty-z-taryfami/platnosc/:id",
    name: "SequencingWorkshopPayment",
    component: SequencingWorkshopPayment,
  },
  {
    path: "/warsztaty-z-taryfami/zapisy/:id",
    name: "TariffWorkshopSignup",
    component: SequencingWorkshopSignup,
  },
  {
    path: "/warsztaty-z-taryfami/zapisano",
    name: "SequencingWorkshopSignupSuccess",
    component: SequencingWorkshopSignupSuccess,
  },

  {
    path: "/warsztaty-z-taryfami/video/:id",
    name: "SequencingWorkshopVideo",
    component: SequencingWorkshopsVideos,
  },
  {
    path: "/warsztaty-z-taryfami/status-platnosci",
    name: "SequencingWorkshopsPaymentSuccess",
    component: SequencingWorkshopsPaymentSuccess,
  },
  {
    path: "/warsztaty-z-taryfami/:id",
    name: "SequencingWorkshops",
    component: SequencingWorkshops,
  },
  {
    path: "/sklep/produkty",
    name: "ShopProducts",
    component: ShopProducts,
  },
  {
    path: "/sklep/produkty/:id",
    name: "ShopProductCart",
    component: ShopProductCart,
  },
  {
    path: "/sklep/koszyk",
    name: "ShopBucket",
    component: ShopBucket,
  },
  {
    path: "/sklep/platnosc",
    name: "ShopProductsPayment",
    component: ShopProductsPayment,
  },
  {
    path: "/sklep/filmy/:id",
    name: "ShopProductVideos",
    component: ShopProductVideos,
  },
  {
    path: "/sklep/filmy/:shopProductId/:id",
    name: "ShopProductVideo",
    component: ShopProductVideo,
  },

  {
    path: "/admin/sklep/produkty",
    name: "ShopProductsManagement",
    component: ShopProductsManagement,
  },

  {
    path: "/admin/sklep/produkty/znizki",
    name: "ShopProductsDiscountsManagement",
    component: ShopProductsDiscountsManagement,
  },

  {
    path: "/admin/sklep/produkty/filmy",
    name: "ShopProductsVideosManagement",
    component: ShopProductsVideosManagement,
  },

  {
    path: "/sklep/status-platnosci",
    name: "ShopProductsPaymentSuccess",
    component: ShopProductsPaymentSuccess,
  },

  {
    path: "/karnety/status-platnosci",
    name: "CoursesTicketsPaymentSuccess",
    component: CoursesTicketsPaymentSuccess,
  },
  {
    path: "/karnety/platnosc/:id",
    name: "CoursesTicketsPayment",
    component: CoursesTicketsPayment,
  },
  {
    path: "/admin/karnety",
    name: "TicketsManagement",
    component: TicketsManagement,
  },
  {
    path: "/admin/karnety-dla-poczatkujacych",
    name: "TicketsBeginnersManagement",
    component: TicketsBeginnersManagement,
  },
  {
    path: "/admin/karnety-linki",
    name: "TicketsLinksManagement",
    component: TicketsLinksManagement,
  },
  {
    path: "/admin/karnety-dla-poczatkujacych-linki",
    name: "TicketsBeginnersLinksManagement",
    component: TicketsBeginnersLinksManagement,
  },
  {
    path: "/admin/subskrypcje-miesieczne-linki",
    name: "TimePackagesLinksManagement",
    component: TimePackagesLinksManagement,
  },
  {
    path: "/admin/harmonogram",
    name: "ScheduleManagement",
    component: ScheduleManagement,
  },
  // {
  //   path: "/admin/subskrypcje-rozszerzone",
  //   name: "SubscriptionsExtendedManagement",
  //   component: SubscriptionsExtendedManagement,
  //   meta: {
  //     requiresAuth: true,
  //     withoutFloatingButton: true,
  //   },
  // },
  // {
  //   path: "/admin/subskrypcje-rozszerzone/kursy",
  //   name: "SubscriptionsItemsExtendedManagement",
  //   component: SubscriptionsItemsExtendedManagement,
  //   meta: {
  //     requiresAuth: true,
  //     withoutFloatingButton: true,
  //   },
  // },
  // {
  //   path: "/admin/kategorie-rozszerzenie",
  //   name: "MovieCategoriesExtendedManagement",
  //   component: MovieCategoriesExtendedManagement,
  //   meta: {
  //     requiresAuth: true,
  //     withoutFloatingButton: true,
  //   },
  // },

  // {
  //   path: "/admin/podkategorie-rozszerzenie",
  //   name: "MovieSubcategoriesForExtendedManagement",
  //   component: MovieSubcategoriesForExtendedManagement,
  //   meta: {
  //     requiresAuth: true,
  //     withoutFloatingButton: true,
  //   },
  // },

  // {
  //   path: "/admin/podkategorie-l2-rozszerzenie",
  //   name: "MovieSubcategoriesL2ForExtendedManagement",
  //   component: MovieSubcategoriesL2ForExtendedManagement,
  //   meta: {
  //     requiresAuth: true,
  //     withoutFloatingButton: true,
  //   },
  // },

  {
    path: "/admin/warsztaty-z-taryfami",
    name: "TariffWorkshopsManagement",
    component: TariffWorkshopsManagement,
  },
  {
    path: "/admin/warsztaty-z-taryfami/kursy",
    name: "TariffWorkshopsItems",
    component: TariffWorkshopsItems,
  },

  {
    path: "/nagrania/:id",
    name: "Videos24h",
    component: Videos24h,
  },

  {
    path: "/warsztaty-teoria-i-praktyka/:workshopId/lekcje/:id/teoria",
    name: "TheoryPracticeWorkshopLessonTheories",
    component: TheoryPracticeWorkshopLessonTheories,
  },
  {
    path: "/warsztaty-teoria-i-praktyka/:workshopId/lekcje/:id/praktyka",
    name: "TheoryPracticeWorkshopLessonPractices",
    component: TheoryPracticeWorkshopLessonPractices,
  },
  {
    path: "/warsztaty-teoria-i-praktyka/status-platnosci",
    name: "TheoryPracticeWorkshopsPaymentSuccess",
    component: TheoryPracticeWorkshopsPaymentSuccess,
  },
  {
    path: "/warsztaty-teoria-i-praktyka/zapisy/:id",
    name: "TheoryPracticeWorkshopSignup",
    component: TheoryPracticeWorkshopSignup,
  },
  {
    path: "/warsztaty-teoria-i-praktyka/zapisano",
    name: "TheoryPracticeWorkshopSignupSuccess",
    component: TheoryPracticeWorkshopSignupSuccess,
  },
  {
    path: "/warsztaty-teoria-i-praktyka/:id",
    name: "TheoryPracticeWorkshopLessons",
    component: TheoryPracticeWorkshopLessons,
  },

  {
    path: "/warsztaty-teoria-i-praktyka/platnosc/:id",
    name: "TheoryPracticeWorkshopPayment",
    component: TheoryPracticeWorkshopPayment,
  },

  {
    path: "/admin/oferty-specjalne",
    name: "SpecialOffersManagement",
    component: SpecialOffersManagement,
  },

  {
    path: "/oferty-specjalne/video/:id",
    name: "SpecialOfferVideo",
    component: SpecialOffersVideos,
  },
  {
    path: "/oferty-specjalne/platnosc/:id",
    name: "SpecialOffersPayment",
    component: SpecialOffersPayment,
  },
  {
    path: "/oferty-specjalne/status-platnosci",
    name: "SpecialOffersPaymentSuccess",
    component: SpecialOffersPaymentSuccess,
  },
  {
    path: "/oferty-specjalne/:id",
    name: "SpecialOffers",
    component: SpecialOffers,
  },

  { path: "*", name: "NotFound", component: NotFound },
];
const scrollBehavior = (to, from) => {
  if (
    (from.name === "Courses" || from.name === "CoursesForBeginners") &&
    (to.name === "VideosDemo" || to.name === "SubcategoryView")
  ) {
    window.localStorage.setItem("scrollView", 1);
  }
  // scroll to top by default
  return { x: 0, y: 0 };
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior,
});

if (isProduction) {
  Sentry.init({
    Vue,
    dsn: SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", "my-site-url.com", /^\//],
      }),
    ],
    tracesSampleRate: 1.0,
  });
}

router.beforeEach(async (to, from, next) => {
  if (
    from &&
    from.fullPath !== "/" &&
    !from.path.includes("/video") &&
    !from.path.includes("/rejestracja") &&
    !from.path.includes("/logowanie") &&
    !from.path.includes("/rejestracja-zakonczona")
  ) {
    window.localStorage.setItem("dagajogaLastRoute", from.fullPath);
  }
  const token = window.localStorage.getItem("token");
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }

  if (to.meta.requiresAuth) {
    try {
      const { exp } = jwt_decode(token);
      if (exp < new Date().getTime() / 1000) {
        next({
          name: "Login",
        });
      } else {
        next();
      }
    } catch (err) {
      next({
        name: "Login",
      });
    }
  }

  if (to.meta.requiresUserAuth) {
    const token = window.localStorage.getItem("userToken");
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    try {
      const { exp } = jwt_decode(token);
      if (exp < new Date().getTime() / 1000) {
        next({
          name: "Auth",
        });
      } else {
        next();
      }
    } catch (err) {
      next({
        name: "Auth",
      });
    }
  }
  next();
});

export default router;
