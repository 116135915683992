import axios from "../axios";
import errorHandler from "../errorHandler";

const packages = {
  namespaced: true,

  state: () => ({
    timePackages: [],
    selectedTimePackage: null,
    timePackageItems: [],
    selectedTimePackageItem: null,
    timePackageItemsNames: [],
    activeSubscriptions: null,
    activeSubscriptionsForBeginners: null,
    workshopsItems: [],
    subCategoryTimePackageItems: [],
    selectedCategoryId: null,
    timePackageItemsForBeginnersNames: [],
    subCategoryL2TimePackageItems: [],
    hasUserActiveExtendedSubscription: false,
    activeSubscriptionsExtended: [],
    timePackageItemsForBeginners: [],
  }),

  mutations: {
    SET_TIME_PACKAGES(state, packages) {
      state.timePackages = packages;
    },
    SET_SELECTED_TIME_PACKAGE(state, timePackage) {
      state.selectedTimePackage = timePackage;
    },
    SET_TIME_PACKAGE_ITEMS(state, timePackageItems) {
      state.timePackageItems = timePackageItems;
    },
    SET_TIME_PACKAGE_ITEMS_FOR_BEGINNERS(state, timePackageItems) {
      state.timePackageItemsForBeginners = timePackageItems;
    },
    SET_SELECTED_TIME_PACKAGE_ITEM(state, timePackageItem) {
      state.selectedTimePackageItem = timePackageItem;
    },
    SET_TIME_PACKAGE_ITEMS_NAMES(state, timePackagesItemsNames) {
      state.timePackageItemsNames = timePackagesItemsNames;
    },
    SET_TIME_PACKAGE_ITEMS_FOR_BEGINNERS_NAMES(state, timePackagesItemsNames) {
      state.timePackageItemsForBeginnersNames = timePackagesItemsNames;
    },
    IMAGE_FETCHED() {},
    SET_ACTIVE_SUBSCRIPTIONS(state, result) {
      state.activeSubscriptions = result;
    },
    SET_ACTIVE_SUBSCRIPTIONS_EXTENDED(state, result) {
      state.activeSubscriptionsExtended = result;
    },
    SET_ACTIVE_SUBSCRIPTIONS_FOR_BEGINNERS(state, result) {
      state.activeSubscriptionsForBeginners = result;
    },
    SET_WORKSHOPS_ITEMS(state, items) {
      state.workshopsItems = items;
    },
    SET_SUBCATEGORY_PACKAGE_ITEMS(state, items) {
      state.subCategoryTimePackageItems = items;
    },
    SET_SELECTED_CATEGORY_ID(state, id) {
      state.selectedCategoryId = id;
    },
    SET_SUBCATEGORYL2_PACKAGE_ITEMS(state, items) {
      state.subCategoryL2TimePackageItems = items;
    },
    SET_USER_HAS_ACTIVE_EXTENDED_SUBSCRIPTION(state, status) {
      state.hasUserActiveExtendedSubscription = status;
    },
  },

  actions: {
    setSelectedCategoryId: async ({ commit }, id) => {
      commit("SET_SELECTED_CATEGORY_ID", id);
    },
    fetchAllTimePackages: async ({ commit }, data) => {
      console.log(type);
      const type = data && data.type ? data.type : null;
      const url = type
        ? `timePackages?type=${type}&withOrders=true`
        : `timePackages`;
      try {
        const packages = await axios.get(url);
        commit("SET_TIME_PACKAGES", packages.data);
      } catch (error) {
        console.log(error);
      }
    },
    fetchAllTimePackagesExtended: async ({ commit }) => {
      const url = `timePackagesExtended`;
      try {
        const packages = await axios.get(url);
        commit("SET_TIME_PACKAGES", packages.data);
      } catch (error) {
        console.log(error);
      }
    },

    fetchAllTimePackageItemsBySubCategoryL2Id: async ({ commit }, id) => {
      try {
        const items = await axios.get(
          `/timePackageItems?movieSubcategoryL2Id=${id}`
        );
        commit("SET_SUBCATEGORYL2_PACKAGE_ITEMS", items.data);
      } catch (error) {
        commit("SET_SUBCATEGORYL2_PACKAGE_ITEMS", []);
      }
    },

    fetchAllTimePackageItemsExtendedBySubCategoryL2Id: async (
      { commit },
      id
    ) => {
      try {
        const items = await axios.get(
          `/timePackageItemsExtended?movieSubcategoryL2Id=${id}`
        );
        commit("SET_SUBCATEGORYL2_PACKAGE_ITEMS", items.data);
      } catch (error) {
        commit("SET_SUBCATEGORYL2_PACKAGE_ITEMS", []);
      }
    },

    fetchAllTimePackageItemsBySubCategoryId: async ({ commit }, id) => {
      try {
        const items = await axios.get(
          `/timePackageItems?movieSubcategoryId=${id}`
        );
        commit("SET_SUBCATEGORY_PACKAGE_ITEMS", items.data);
      } catch (error) {
        commit("SET_SUBCATEGORY_PACKAGE_ITEMS", []);
      }
    },

    fetchAllTimePackageItemsExtendedBySubCategoryId: async ({ commit }, id) => {
      try {
        const items = await axios.get(
          `/timePackageItemsExtended?movieSubcategoryId=${id}`
        );
        commit("SET_SUBCATEGORY_PACKAGE_ITEMS", items.data);
      } catch (error) {
        commit("SET_SUBCATEGORY_PACKAGE_ITEMS", []);
      }
    },

    fetchAllTimePackageItems: async ({ commit }, data) => {
      try {
        let packageItems = [];
        if (data) {
          packageItems = await axios.get("timePackageItems", {
            params: {
              includeItemsWithSubcategory: data.includeItemsWithSubcategory
                ? data.includeItemsWithSubcategory
                : "",
              lastWatched:
                data && (data.lastWatched || data.categoryId === "lastWatched")
                  ? true
                  : "",
              favorites:
                data && (data.favorites || data.categoryId === "favourites")
                  ? true
                  : "",
              movieCategoryId:
                data && data.categoryId && !data.favorites && !data.lastWatched
                  ? data.categoryId
                  : "",
              forBeginners:
                data &&
                data.forBeginners &&
                data.forBeginners !== null &&
                data.forBeginners !== undefined
                  ? true
                  : false,
              search: data && data.search ? data.search : "",
            },
          });
        } else {
          packageItems = await axios.get("timePackageItems");
        }
        commit("SET_TIME_PACKAGE_ITEMS", packageItems.data);
      } catch (error) {
        commit("SET_TIME_PACKAGE_ITEMS", []);
      }
    },

    fetchAllTimePackageItemsForBeginners: async ({ commit }, data) => {
      try {
        let packageItems = [];
        if (data) {
          packageItems = await axios.get("timePackageItems", {
            params: {
              includeItemsWithSubcategory: data.includeItemsWithSubcategory
                ? data.includeItemsWithSubcategory
                : "",
              lastWatched:
                data && (data.lastWatched || data.categoryId === "lastWatched")
                  ? true
                  : "",
              favorites:
                data && (data.favorites || data.categoryId === "favourites")
                  ? true
                  : "",
              movieCategoryId:
                data && data.categoryId && !data.favorites && !data.lastWatched
                  ? data.categoryId
                  : "",
              forBeginners: true,
              search: data && data.search ? data.search : "",
            },
          });
        } else {
          packageItems = await axios.get("timePackageItems", {
            params: { forBeginners: true },
          });
        }
        commit("SET_TIME_PACKAGE_ITEMS_FOR_BEGINNERS", packageItems.data);
      } catch (error) {
        commit("SET_TIME_PACKAGE_ITEMS", []);
      }
    },

    fetchAllTimePackageItemsExtended: async ({ commit }, data) => {
      try {
        let packageItems = [];
        if (data) {
          packageItems = await axios.get("timePackageItemsExtended", {
            params: {
              includeItemsWithSubcategory: data.includeItemsWithSubcategory
                ? data.includeItemsWithSubcategory
                : "",
              lastWatched:
                data && (data.lastWatched || data.categoryId === "lastWatched")
                  ? true
                  : "",
              favorites:
                data && (data.favorites || data.categoryId === "favourites")
                  ? true
                  : "",
              movieCategoryId:
                data && data.categoryId && !data.favorites && !data.lastWatched
                  ? data.categoryId
                  : "",
              search: data && data.search ? data.search : "",
            },
          });
        } else {
          packageItems = await axios.get("timePackageItemsExtended");
        }
        commit("SET_TIME_PACKAGE_ITEMS", packageItems.data);
      } catch (error) {
        commit("SET_TIME_PACKAGE_ITEMS", []);
      }
    },

    fetchWorkshopsItems: async ({ commit }) => {
      try {
        const url = `management/workshopsItems`;
        const packageItems = await axios.get(url);
        commit("SET_WORKSHOPS_ITEMS", packageItems.data);
      } catch (error) {
        commit("SET_WORKSHOPS_ITEMS", []);
      }
    },

    addToFavorite: async ({ dispatch }, data) => {
      try {
        let url = "";
        if (data.timePackageItemId) {
          url = `favoriteUserItems/timePackageItems/${data.timePackageItemId}`;
        } else {
          url = `favoriteUserItems/timePackageItemsExtended/${data.timePackageItemExtendedId}`;
        }

        const response = await axios.post(url);
        if (!data.withoutFetching) {
          if (data.timePackageItemId) {
            await dispatch("fetchAllTimePackageItems", {
              favorites: data.favorites,
              categoryId: data.categoryId,
              lastWatched: data.lastWatched,
            });
          } else {
            await dispatch("fetchAllTimePackageItemsExtended", {
              favorites: data.favorites,
              categoryId: data.categoryId,
              lastWatched: data.lastWatched,
            });
          }
        } else {
          return response.data;
        }
      } catch (error) {
        console.log(error);
      }
    },

    deleteFromFavourites: async ({ dispatch }, data) => {
      try {
        const isExtendedSubscription = data.isExtendedSubscription;
        const url = `favoriteUserItems/${data.favoriteItemId}`;
        await axios.delete(url);
        if (!data.withoutFetching) {
          if (!isExtendedSubscription) {
            await dispatch("fetchAllTimePackageItems", {
              favorites: data.favorites,
              categoryId: data.categoryId,
              lastWatched: data.lastWatched,
            });
          } else {
            await dispatch("fetchAllTimePackageItemsExtended", {
              favorites: data.favorites,
              categoryId: data.categoryId,
              lastWatched: data.lastWatched,
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    },

    fetchAllTimePackagesItemsNames: async ({ commit }) => {
      try {
        const packageItemsNames = await axios.get("timePackageItemsNames");
        commit("SET_TIME_PACKAGE_ITEMS_NAMES", packageItemsNames.data);
      } catch (error) {
        commit("SET_TIME_PACKAGE_ITEMS_NAMES", []);
      }
    },

    fetchAllTimePackagesItemsNamesExtended: async ({ commit }) => {
      try {
        const packageItemsNames = await axios.get(
          "timePackageItemsNamesExtended"
        );
        commit("SET_TIME_PACKAGE_ITEMS_NAMES", packageItemsNames.data);
      } catch (error) {
        commit("SET_TIME_PACKAGE_ITEMS_NAMES", []);
      }
    },

    fetchAllTimePackagesItemsForBeginnersNames: async ({ commit }) => {
      try {
        const packageItemsNames = await axios.get(
          "timePackageItemsNamesForBeginners"
        );
        commit(
          "SET_TIME_PACKAGE_ITEMS_FOR_BEGINNERS_NAMES",
          packageItemsNames.data
        );
      } catch (error) {
        commit("SET_TIME_PACKAGE_ITEMS_FOR_BEGINNERS_NAMES", []);
      }
    },

    setSelectedTimePackage: async ({ commit }, data) => {
      try {
        commit("SET_SELECTED_TIME_PACKAGE", data.timePackage);
      } catch (error) {
        console.log(error);
      }
    },
    setSelectedTimePackageItem: async ({ commit }, data) => {
      try {
        commit("SET_SELECTED_TIME_PACKAGE_ITEM", data.timePackageItem);
      } catch (error) {
        console.log(error);
      }
    },

    async addPackage({ dispatch }, object) {
      const type = object.type ? object.type : null;
      if (type) {
        object.data.type = type;
      }
      try {
        const response = await axios.post(
          `management/timePackages`,
          object.data
        );
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie dodano!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        await dispatch("fetchAllTimePackages", {
          type,
        });
        return response.data;
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async addPackageExtended({ dispatch }, object) {
      try {
        await axios.post(`management/timePackagesExtended`, object.data);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie dodano!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        await dispatch("fetchAllTimePackagesExtended");
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async addTimePackageItem({ dispatch }, object) {
      try {
        const formData = new FormData();
        const {
          file,
          name,
          description,
          link,
          linkDropbox,
          demoLink,
          demoLinkDropbox,
          movieCategoryId,
          movieSubCategoryId,
          timePackageId,
          imagePath,
          copiedTimePackageItemId,
          type,
          movieSubCategoryL2Id,
          keywords,
        } = object.data;
        if (file) {
          formData.append("file", file);
        }
        if (name) {
          formData.append("name", name);
        }
        if (description) {
          formData.append("description", description);
        }
        if (link !== null && link !== undefined) {
          formData.append("link", link);
        }
        if (demoLink !== null && demoLink !== undefined) {
          formData.append("demoLink", demoLink);
        }
        if (linkDropbox !== null && linkDropbox !== undefined) {
          formData.append("linkDropbox", linkDropbox);
        }
        if (demoLinkDropbox !== null && demoLinkDropbox !== undefined) {
          formData.append("demoLinkDropbox", demoLinkDropbox);
        }
        if (movieCategoryId && movieCategoryId != "Brak") {
          formData.append("movieCategoryId", movieCategoryId);
        }
        if (movieSubCategoryId && movieSubCategoryId != "Brak") {
          formData.append("movieSubCategoryId", movieSubCategoryId);
        }
        if (movieSubCategoryL2Id && movieSubCategoryL2Id != "Brak") {
          formData.append("movieSubCategoryL2Id", movieSubCategoryL2Id);
        }
        if (timePackageId) {
          formData.append("timePackageId", timePackageId);
        }
        if (imagePath) {
          formData.append("imagePath", imagePath);
        }
        if (copiedTimePackageItemId) {
          formData.append("copiedTimePackageItemId", copiedTimePackageItemId);
        }
        if (type && type === "FOR-BEGINNERS") {
          formData.append("forBeginners", true);
        }
        if (keywords !== null && keywords !== undefined) {
          formData.append("keywords", keywords);
        }
        await axios.post(`management/timePackageItems`, formData);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie dodano!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        if (type && type === "FOR-BEGINNERS") {
          await dispatch("fetchAllTimePackageItems", {
            forBeginners: true,
            includeItemsWithSubcategory: true,
          });
        } else if (timePackageId) {
          await dispatch("fetchWorkshopsItems");
        } else {
          await dispatch("fetchAllTimePackageItems", {
            includeItemsWithSubcategory: true,
          });
        }
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async addTimePackageItemExtended({ dispatch }, object) {
      try {
        const formData = new FormData();
        const {
          file,
          name,
          description,
          link,
          linkDropbox,
          demoLink,
          demoLinkDropbox,
          movieCategoryId,
          movieSubCategoryId,
          imagePath,
          copiedTimePackageItemId,
          movieSubCategoryL2Id,
          keywords,
        } = object.data;
        if (file) {
          formData.append("file", file);
        }
        if (name) {
          formData.append("name", name);
        }
        if (description) {
          formData.append("description", description);
        }
        if (link !== null && link !== undefined) {
          formData.append("link", link);
        }
        if (demoLink !== null && demoLink !== undefined) {
          formData.append("demoLink", demoLink);
        }
        if (linkDropbox !== null && linkDropbox !== undefined) {
          formData.append("linkDropbox", linkDropbox);
        }
        if (demoLinkDropbox !== null && demoLinkDropbox !== undefined) {
          formData.append("demoLinkDropbox", demoLinkDropbox);
        }
        if (movieCategoryId && movieCategoryId != "Brak") {
          formData.append("movieCategoryId", movieCategoryId);
        }
        if (movieSubCategoryId && movieSubCategoryId != "Brak") {
          formData.append("movieSubCategoryId", movieSubCategoryId);
        }
        if (movieSubCategoryL2Id && movieSubCategoryL2Id != "Brak") {
          formData.append("movieSubCategoryL2Id", movieSubCategoryL2Id);
        }
        if (imagePath) {
          formData.append("imagePath", imagePath);
        }
        if (copiedTimePackageItemId) {
          formData.append("copiedTimePackageItemId", copiedTimePackageItemId);
        }
        if (keywords !== null && keywords !== undefined) {
          formData.append("keywords", keywords);
        }
        await axios.post(`management/timePackageItemsExtended`, formData);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie dodano!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });

        await dispatch("fetchAllTimePackageItemsExtended", {
          includeItemsWithSubcategory: true,
        });
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async updateTimePackageItem({ dispatch }, object) {
      try {
        const formData = new FormData();
        const {
          file,
          name,
          description,
          link,
          linkDropbox,
          demoLink,
          demoLinkDropbox,
          movieCategoryId,
          movieSubCategoryId,
          timePackageId,
          movieSubCategoryL2Id,
          keywords,
        } = object.data;
        if (file) {
          formData.append("file", file);
        }
        if (name) {
          formData.append("name", name);
        }
        if (description) {
          formData.append("description", description);
        }
        if (link !== null && link !== undefined) {
          formData.append("link", link);
        }
        if (demoLink !== null && demoLink !== undefined) {
          formData.append("demoLink", demoLink);
        }
        if (movieCategoryId && movieCategoryId != "Brak") {
          formData.append("movieCategoryId", movieCategoryId);
        }
        if (movieSubCategoryId && movieSubCategoryId != "Brak") {
          formData.append("movieSubCategoryId", movieSubCategoryId);
        }
        if (timePackageId) {
          formData.append("timePackageId", timePackageId);
        }
        if (movieSubCategoryL2Id && movieSubCategoryL2Id != "Brak") {
          formData.append("movieSubCategoryL2Id", movieSubCategoryL2Id);
        }
        if (keywords !== null && keywords !== undefined) {
          formData.append("keywords", keywords);
        }
        if (linkDropbox !== null && linkDropbox !== undefined) {
          formData.append("linkDropbox", linkDropbox);
        }
        if (demoLinkDropbox !== null && demoLinkDropbox !== undefined) {
          formData.append("demoLinkDropbox", demoLinkDropbox);
        }
        await axios.put(
          `management/timePackageItems/${object.packageItemId}`,
          formData
        );
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Edycja przebiegła pomyślnie!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        if (object.type && object.type === "FOR-BEGINNERS") {
          await dispatch("fetchAllTimePackageItems", {
            forBeginners: true,
            includeItemsWithSubcategory: true,
          });
        } else if (timePackageId) {
          await dispatch("fetchWorkshopsItems");
        } else {
          await dispatch("fetchAllTimePackageItems", {
            includeItemsWithSubcategory: true,
          });
        }
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async updateTimePackageItemExtended({ dispatch }, object) {
      try {
        const formData = new FormData();
        const {
          file,
          name,
          description,
          link,
          linkDropbox,
          demoLink,
          demoLinkDropbox,
          movieCategoryId,
          movieSubCategoryId,
          movieSubCategoryL2Id,
          keywords,
        } = object.data;
        if (file) {
          formData.append("file", file);
        }
        if (name) {
          formData.append("name", name);
        }
        if (description) {
          formData.append("description", description);
        }
        if (link !== null && link !== undefined) {
          formData.append("link", link);
        }
        if (demoLink !== null && demoLink !== undefined) {
          formData.append("demoLink", demoLink);
        }
        if (linkDropbox !== null && linkDropbox !== undefined) {
          formData.append("linkDropbox", linkDropbox);
        }
        if (demoLinkDropbox !== null && demoLinkDropbox !== undefined) {
          formData.append("demoLinkDropbox", demoLinkDropbox);
        }
        if (movieCategoryId && movieCategoryId != "Brak") {
          formData.append("movieCategoryId", movieCategoryId);
        }
        if (movieSubCategoryId && movieSubCategoryId != "Brak") {
          formData.append("movieSubCategoryId", movieSubCategoryId);
        }
        if (movieSubCategoryL2Id && movieSubCategoryL2Id != "Brak") {
          formData.append("movieSubCategoryL2Id", movieSubCategoryL2Id);
        }
        if (keywords !== null && keywords !== undefined) {
          formData.append("keywords", keywords);
        }
        await axios.put(
          `management/timePackageItemsExtended/${object.packageItemId}`,
          formData
        );
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Edycja przebiegła pomyślnie!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });

        await dispatch("fetchAllTimePackageItemsExtended", {
          includeItemsWithSubcategory: true,
        });
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async updatePackage({ dispatch }, object) {
      const type = object.type ? object.type : null;
      try {
        await axios.put(
          `management/timePackages/${object.packageId}`,
          object.data
        );
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Edycja przebiegła pomyślnie!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        if (object && object.type && object.type === "FOR-BEGINNERS") {
          await dispatch("fetchAllTimePackageItems", {
            forBeginners: true,
          });
        } else {
          await dispatch("fetchAllTimePackages", {
            type,
          });
        }
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async updatePackageExtended({ dispatch }, object) {
      try {
        await axios.put(
          `management/timePackagesExtended/${object.packageId}`,
          object.data
        );
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Edycja przebiegła pomyślnie!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });

        await dispatch("fetchAllTimePackagesExtended");
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async deletePackage({ dispatch }, object) {
      const type = object.type ? object.type : null;
      try {
        await axios.delete(`management/timePackages/${object.id}`);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie usunięto!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        await dispatch("fetchAllTimePackages", {
          type,
        });
      } catch (error) {
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async deletePackageExtended({ dispatch }, object) {
      try {
        await axios.delete(`management/timePackagesExtended/${object.id}`);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie usunięto!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        await dispatch("fetchAllTimePackagesExtended");
      } catch (error) {
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async deleteTimePackageItem({ dispatch }, data) {
      try {
        await axios.delete(`management/timePackageItems/${data.id}`);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie usunięto!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        if (data && data.type && data.type === "FOR-BEGINNERS") {
          await dispatch("fetchAllTimePackageItems", {
            forBeginners: true,
            includeItemsWithSubcategory: true,
          });
        } else if (data && data.type && data.type === "WORKSHOPS") {
          await dispatch("fetchWorkshopsItems");
        } else if (data && data.type && data.type === "FOR-BEGINNERS") {
          await dispatch("fetchAllTimePackageItems", {
            forBeginners: true,
          });
        } else {
          await dispatch("fetchAllTimePackageItems", {
            includeItemsWithSubcategory: true,
          });
        }
      } catch (error) {
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async deleteTimePackageItemExtended({ dispatch }, data) {
      try {
        await axios.delete(`management/timePackageItemsExtended/${data.id}`);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie usunięto!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });

        await dispatch("fetchAllTimePackageItemsExtended", {
          includeItemsWithSubcategory: true,
        });
      } catch (error) {
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async getTimePackageItemImageSrc({ commit }, data) {
      try {
        const response = await axios.get(
          `/timePackageItems/${data.timePackageItemId}/image`,
          {
            responseType: "blob",
          }
        );

        const blob = response.data;
        const urlCreator = window.URL || window.webkitURL;
        const imageUrl = urlCreator.createObjectURL(blob);
        commit("IMAGE_FETCHED", true);
        return imageUrl;
      } catch (error) {
        console.log(error);
        return null;
      }
    },

    async getTimePackageItemById({ commit }, id) {
      try {
        const response = await axios.get(`/timePackageItems/${id}`);
        commit("SET_SELECTED_TIME_PACKAGE", response.data);
        return response.data;
      } catch (error) {
        console.log(error);
        return null;
      }
    },

    async getTimePackageItemExtendedById({ commit }, id) {
      try {
        const response = await axios.get(`/timePackageItemsExtended/${id}`);
        commit("SET_SELECTED_TIME_PACKAGE", response.data);
        return response.data;
      } catch (error) {
        console.log(error);
        return null;
      }
    },

    async fetchActiveSubscriptions({ commit }) {
      try {
        const response = await axios.get(`/active-subscription`);
        commit("SET_ACTIVE_SUBSCRIPTIONS", response.data);
      } catch (error) {
        commit("SET_ACTIVE_SUBSCRIPTIONS", null);
      }
    },

    async setActiveSubscriptionsToNull({ commit }) {
      commit("SET_ACTIVE_SUBSCRIPTIONS", null);
    },

    async setActiveSubscriptionsExtendedToNull({ commit }) {
      commit("SET_ACTIVE_SUBSCRIPTIONS_EXTENDED", null);
    },

    async fetchActiveSubscriptionsForBeginners({ commit }) {
      try {
        const response = await axios.get(`/active-subscription-for-beginners`);
        commit("SET_ACTIVE_SUBSCRIPTIONS_FOR_BEGINNERS", response.data);
      } catch (error) {
        commit("SET_ACTIVE_SUBSCRIPTIONS_FOR_BEGINNERS", null);
      }
    },

    async fetchActiveSubscriptionsExtended({ commit }) {
      try {
        const response = await axios.get(`/active-subscription-extended`);
        commit("SET_ACTIVE_SUBSCRIPTIONS_EXTENDED", response.data);
      } catch (error) {
        commit("SET_ACTIVE_SUBSCRIPTIONS_EXTENDED", null);
      }
    },

    async setActiveSubscriptionsForBeginnersToNull({ commit }) {
      commit("SET_ACTIVE_SUBSCRIPTIONS_FOR_BEGINNERS", null);
    },

    async checkUserHasActiveExtendedSubscription({ commit }) {
      try {
        const url = `timePackages/hasUserActiveExtendedSubscription`;
        const response = await axios.get(url);
        commit(
          "SET_USER_HAS_ACTIVE_EXTENDED_SUBSCRIPTION",
          response.data.status
        );
      } catch (error) {
        commit("SET_USER_HAS_ACTIVE_EXTENDED_SUBSCRIPTION", false);
        console.log(error);
      }
    },
  },

  getters: {
    getAllTimePackages: (state) => state.timePackages,
    getSelectedTimePackage: (state) => state.selectedTimePackage,
    getTimePackageItems: (state) => state.timePackageItems,
    getSelectedTimePackageItem: (state) => state.timePackageItem,
    getAllTimePackagesItemsNames: (state) => state.timePackageItemsNames,
    getActiveSubscriptions: (state) => state.activeSubscriptions,
    getWorkshopsItems: (state) => state.workshopsItems,
    getSubCategoryTimePackageItems: (state) =>
      state.subCategoryTimePackageItems,
    getSelectedCategoryId: (state) => state.selectedCategoryId,
    getAllTimePackagesItemsForBeginnersNames: (state) =>
      state.timePackageItemsForBeginnersNames,
    getActiveSubscriptionsForBeginners: (state) =>
      state.activeSubscriptionsForBeginners,
    getSubCategoryL2TimePackageItems: (state) =>
      state.subCategoryL2TimePackageItems,
    hasUserActiveExtendedSubscription: (state) =>
      state.hasUserActiveExtendedSubscription,
    getActiveSubscriptionsExtended: (state) =>
      state.activeSubscriptionsExtended,
    getTimePackageItemsForBeginners: (state) =>
      state.timePackageItemsForBeginners,
  },
};
export default packages;
