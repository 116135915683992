<template>
  <div>
    <ReturnBtn
      :path="this.path ? this.path : `subskrypcja`"
      blackColor
    ></ReturnBtn>
    <v-row class="card">
      <v-col v-if="video">
        <video-player
          :videoId="getCourseYoutubeId()"
          :dropboxLink="getDropboxLink"
        />
      </v-col>
      <div class="text-under">
        <h3 class="btn-gradient">
          {{ video ? video.name : "" }}
        </h3>
        <p v-html="video ? htmlToFormattedText(video.description) : ''"></p>
      </div>
    </v-row>
  </div>
</template>

<script>
import ReturnBtn from "../../components/ReturnBtn.vue";
import VideoPlayer from "../../components/VideoPlayer.vue";

export default {
  name: "ShopProductVideo",

  components: {
    ReturnBtn,
    VideoPlayer,
  },

  data: () => ({
    playing: true,
    loggedIn: false,
    path: null,
  }),

  async mounted() {
    try {
      this.path = this.$route.params.path;
      await this.$store.dispatch("shopProducts/fetchShopProductVideo", {
        productId: this.$route.params.shopProductId,
        shopProductVideoId: this.$route.params.id,
      });

      const hasUserAccess = await this.$store.dispatch(
        "shopProducts/checkUserHasAccessToProductVideos",
        { shopProductId: this.$route.params.shopProductId }
      );
      if (!hasUserAccess) {
        this.redirectToMainPage();
      }
    } catch (error) {
      this.redirectToMainPage();
      this.$store.dispatch(
        "snackbar/setSnackbar",
        {
          color: "red",
          icon: "exclamation-triangle",
          message:
            "Musisz być zalogowany oraz posiadać wykupiony dostęp do tego produktu",
        },
        { root: true }
      );
      this.$store.dispatch("snackbar/toggleSnackbar", true, { root: true });
    }
  },

  methods: {
    redirectToMainPage() {
      this.$router.push({
        name: "Courses",
      });
    },
    htmlToFormattedText(text) {
      const regex =
        /((http|https|ftp|ftps):\/\/[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,3}(\/\S*)?)/g;
      text = text.replace(regex, '<a href="$1">$1</a>');
      return text;
    },

    getCourseYoutubeId() {
      const splittedCourseLink = this.video.link.split("/");
      let videoId = splittedCourseLink[splittedCourseLink.length - 1];
      if (videoId) {
        videoId = videoId.replace(/ /g, "");
      }
      return videoId;
    },
  },

  computed: {
    getDropboxLink() {
      let courseLink = this.video && this.video.link ? this.video.link : "";
      if (courseLink.includes("dropbox")) {
        const videoLink = courseLink.replace("dl=0", "raw=1");
        return videoLink;
      } else {
        return "";
      }
    },
    video() {
      return this.$store.getters["shopProducts/getShopProductVideo"];
    },
  },
};
</script>

<style scoped>
.card {
  width: 70vw;
  margin: 0 auto;
  margin-top: 5rem;
  margin-bottom: 40px;
  padding: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  border-radius: 10px;
  display: block;
  position: relative;
  padding-top: 4rem;
}
.text-under {
  padding: 20px 30px;
  border-top: 1px solid lightgray;
}
h3 {
  margin-bottom: 20px;
  font-size: 1.3rem;
  font-weight: 400;
}

.no-access .btn-gradient {
  width: 220px;
  padding: 8px 22px;
  border-radius: 30px;
  font-size: 1rem;
  margin: 15px;
}
.no-access {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  padding-top: 3rem;
  height: 100%;
  min-height: 300px;
  max-height: 600px;
  width: 80%;
  margin: 0 auto;
  background-image: url("../../assets/background-video.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.switch {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
}
.switch div {
  padding: 1rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  font-size: 15px;
  cursor: pointer;
}
.switch .btn-gradient {
  height: 40px;
  display: flex;
  align-items: center;
  background: rgb(233, 230, 230);
  color: black;
}
.switch .btn-gradient:hover {
  transform: none;
}
.selectedSwitch {
  background: rgb(179, 106, 103) !important;
  background: linear-gradient(
    90deg,
    rgba(179, 106, 103, 1) 0%,
    rgba(223, 44, 38, 1) 100%
  ) !important;
  color: #fff !important;
}
@media screen and (max-width: 700px) {
  .card {
    width: 90vw;
  }
}
@media screen and (max-width: 550px) {
  .no-access h3 {
    font-size: 1.1rem;
  }
}
@media screen and (max-width: 450px) {
  .no-access .btn-gradient {
    font-size: 0.8;
    width: 160px;
  }
  .no-access h3 {
    font-size: 1rem;
  }
  .card {
    width: 100vw;
  }
  .text-under {
    font-size: 0.8rem;
    padding: 20px 5px;
  }
}
@media screen and (max-width: 375px) {
  .no-access h3 {
    font-size: 0.8rem;
  }
}
</style>
