<template>
  <div v-if="dropboxLink" class="dropboxvideo-container">
    <video
      autoplay
      controls
      disablePictureInPicture
      controlsList="nodownload"
      height="100%"
    >
      <source :src="dropboxLink" type="video/mp4" />
      Twoja przeglądarka nie obsługuje wideo.
    </video>
  </div>
  <div
    v-else-if="videoId"
    :id="resourceId ? `yt-player-${resourceId}` : videoId"
    class="yt-player"
    data-plyr-provider="youtube"
    :data-plyr-embed-id="videoId"
    style="width: 100%; height: 100%"
  />
</template>

<script>
import Plyr from "plyr";
export default {
  props: {
    videoId: String,
    resourceId: String,
    dropboxLink: String,
  },
  mounted() {
    if (this.resourceId) {
      this.player = new Plyr(`#yt-player-${this.resourceId}`, {});
    } else {
      this.player = new Plyr(".yt-player", {});
    }
  },
  data() {
    return {
      player: null,
    };
  },
};
</script>

<style scoped>
.dropboxvideo-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

video {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}
</style>
