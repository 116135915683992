var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ReturnBtn',{attrs:{"path":this.path
        ? this.path
        : ("" + (_vm.selectedVideo && _vm.selectedVideo.forBeginners
              ? 'subskrypcja-podstawy'
              : 'subskrypcja')),"blackColor":""}}),_c('v-row',{staticClass:"card"},[_c('div',{staticClass:"switch"},[(
          _vm.getDemoCourseYoutubeId() !== '' || _vm.getDemoCourseDropboxLink !== ''
        )?_c('div',{staticClass:"demo btn-gradient",class:{ selectedSwitch: _vm.selectedVersion === 'DEMO' },on:{"click":function($event){return _vm.changeVersion()}}},[_vm._v(" Demo ")]):_vm._e(),_c('div',{staticClass:"full-video btn-gradient",class:{ selectedSwitch: _vm.selectedVersion === 'FULL' }},[_vm._v(" Pełna wersja ")])]),(
        _vm.selectedVersion === 'FULL' &&
        (_vm.getCourseYoutubeId() !== '' || _vm.getDropboxLink !== '')
      )?_c('v-col',[(_vm.hasActiveSubscription && !_vm.notAllowedToWatch)?_c('div',[_c('video-player',{attrs:{"videoId":_vm.getCourseYoutubeId(),"dropboxLink":_vm.getDropboxLink}})],1):_c('div',{staticClass:"text-center no-access"},[_c('h3',[_vm._v(" Aby uzyskać dostęp do kursów, musisz posiadać konto w serwisie oraz mieć aktywną subskrypcję. ")]),(!_vm.loggedIn)?_c('button',{staticClass:"background",on:{"click":_vm.moveToAuth}},[_vm._v(" Zaloguj się ")]):_vm._e(),_c('button',{staticClass:"background",on:{"click":_vm.moveToSubscriptions}},[_vm._v(" Wykup dostęp ")])])]):_vm._e(),(_vm.selectedVideo && _vm.selectedVideo.viewsCounter)?_c('p',{staticClass:"viewsCounter",attrs:{"align":"right"}},[_vm._v(" wyświetlenia: "+_vm._s(_vm.selectedVideo.viewsCounter)+" ")]):_vm._e(),(_vm.selectedVideo && _vm.loggedIn)?_c('p',{staticClass:"favorite-button",attrs:{"align":"right"},on:{"click":_vm.handleFavourites}},[(!_vm.selectedVideo.favorite)?_c('font-awesome-icon',{staticClass:"favorite-icon",attrs:{"icon":"plus"}}):_c('font-awesome-icon',{staticClass:"favorite-icon",attrs:{"icon":"minus"}}),_vm._v(" "+_vm._s(_vm.selectedVideo.favorite ? "Usuń z ulubionych" : "Dodaj do ulubionych")+" ")],1):_vm._e(),(_vm.selectedVideo)?_c('add-video-to-schedule',{attrs:{"selectedVideo":_vm.selectedVideo}}):_vm._e(),_c('div',{staticClass:"text-under"},[_c('h3',{staticClass:"background"},[_vm._v(" "+_vm._s(_vm.selectedVideo ? _vm.selectedVideo.name : _vm.course ? _vm.course.name : "")+" ")]),_c('p',{domProps:{"innerHTML":_vm._s(
          _vm.selectedVideo
            ? _vm.htmlToFormattedText(_vm.selectedVideo.description)
            : _vm.course
            ? _vm.htmlToFormattedText(_vm.course.description)
            : ''
        )}})])],1),(_vm.selectedVideo)?_c('CommentSection',{attrs:{"id":_vm.selectedVideo ? _vm.selectedVideo.id : null}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }