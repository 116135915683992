<template>
  <v-container fluid>
    <v-layout row wrap>
      <v-flex xs12 sm6 offset-sm3 mt-6 class="transparent-bg">
        <h1 class="text-center">
          Dziękujemy za skorzystanie z naszego serwisu.
        </h1>
        <blockquote class="blockquote text-center">
          Jeśli płatność przebiegła pomyślnie, link do zakupionej oferty
          specjalnej otrzymasz na swojego maila oraz znajdziesz ją w zakładce
          "Moje konto"
        </blockquote>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  name: "SpecialOffersPaymentSuccess",
  metaInfo: {
    title: "Dagajoga oferta specjalna - portal jogi online",
  },
  methods: {},
};
</script>

<style scoped>
.telegram {
  position: absolute;
  bottom: 0;
  right: 1rem;
}
.transparent-bg {
  position: relative;
  padding: 50px;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.664);
}
i {
  font-size: 120px;
}
.center {
  display: flex;
  justify-content: center;
  margin-right: 20px;
  margin-top: 15px;
  cursor: default;
}
a {
  text-decoration: none;
}
</style>
