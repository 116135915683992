<template>
  <div>
    <div class="box">
      <p class="title">{{ practice.name }}</p>
      <div class="video" v-if="practice && practice.link">
        <video-player
          :key="Math.floor(Math.random() * 10)"
          :videoId="practice.link"
          :resourceId="practice.id"
          :width="500"
          :dropboxLink="getDropboxLink"
        />
      </div>
      <p class="description" v-html="practice.description"></p>
    </div>
  </div>
</template>

<script>
import VideoPlayer from "../../../components/VideoPlayer.vue";
export default {
  components: { VideoPlayer },
  name: "TheoryPracticeLessonPractice",
  props: ["practice", "customKey"],
  methods: {},
  computed: {
    getDropboxLink() {
      let courseLink =
        this.practice && this.practice.link ? this.practice.link : "";
      if (courseLink.includes("dropbox")) {
        const videoLink = courseLink.replace("dl=0", "raw=1");
        return videoLink;
      } else {
        return "";
      }
    },
  },
};
</script>

<style scoped>
.box {
  margin-top: 1rem;
  margin-bottom: 4rem;
}
.video {
  margin: 0 auto;
  margin-bottom: 2rem;
  max-width: 500px;
}
.title {
  font-weight: bold;
}
</style>